import React, { useEffect, useState, useRef } from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportRow3 from '../components/MedicalPassportRow3';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import { useValue } from '../ValueContext';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  liver,
  airway,
  ob,
} from './paths';
import Footer from './components/Footer';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import get from 'lodash/get';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import { getcontent } from '../golfCoast/pages/contentlimit'
import toHighlightsString5 from '../../../../../questionnaire/toHighlightsString5';
import MedicalPassportTextArea2 from '../components/MedicalPassportTextArea2';
import toHighlightsString4 from '../../../../../questionnaire/toHighlightsString4';

function yesOrNo(value) {
  if (value === true) {
    return 'yes';
  } else if (value === false) {
    return 'no';
  }
  return '';
}

const Page5 = () => {
  const { questionnaire, questionnaireAnswers } = useValue();
  let patientProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
    ''
  )
    ? 'Patient:' +
    get(
      questionnaireAnswers,
      'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text2.text',
      ''
    )
    : '';

  let familyProblem = get(
    questionnaireAnswers,
    'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
    ''
  )
    ? 'Family:' +
    get(
      questionnaireAnswers,
      'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
      ''
    )
    : '';

  let anesthesiaProblem = patientProblem + '\n' + familyProblem;

  let alcohol = yesOrNo(get(questionnaireAnswers, 'sections.social.schema.drinking.yes', ''));
  let drugs = yesOrNo(get(questionnaireAnswers, 'sections.social.schema.drugs.yes', ''));
  // allergy
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  function combineArrays(arr1, arr2) {
    let result = '';

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      result += arr1[i] + ', ' + arr2[i] + '\n';
    }

    return result;
  }

  const smoker =
    get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '') === 'N'
      ? get(questionnaireAnswers, 'sections.social.schema.smoking.option', '')
      : get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '');

  function isSmoker(value) {
    if (value === 'Former Smoker') {
      return (
        'Former Smoker, ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.former_amount.option') +
        ', ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.former_quit.text')
      );
    } else if (value === 'Current Smoker') {
      return (
        'Current Smoker, ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.current_amount.option') +
        ', ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.current_howLong.text')
      );
    } else if (value === 'Never Smoked') {
      return 'Never Smoked';
    }
    return '';
  }
  const surgery = getcontent(toHighlightsString, [['priorSurgeries']], 70);
  const medication = getcontent(toHighlightsString, [['medications']], 70);
  const allergy = getcontent(toHighlightsString4, [['allergies']], 70);

  const medicationList = get(
    questionnaireAnswers,
    'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
    []
  );


  const pageRef = useRef(null);
  const medicationsRef = useRef(null);

  const [pageStyle, setPageStyle] = useState({});
  const [medicationsHeight, setMedicationsHeight] = useState(0);


  useEffect(() => {
    const handleResize = () => {
      if (pageRef.current) {
        const pageHeight = pageRef.current.offsetHeight;
        if (medicationsRef.current) {
          setMedicationsHeight(medicationsRef.current.offsetHeight);
        }
        if (pageHeight > 1064) {
          setPageStyle({ minHeight: 2150 });
          if (medicationsHeight > 1079) {
            setPageStyle({ minHeight: 3000 });
          } 
        } else {
          setPageStyle({});
        }
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [medicationsHeight]);


  return (
    <div id="page5" className="page" style={{ ...pageStyle, fontFamily: 'Arial', gap: '10px', paddingTop: '7px' }}>
      <div ref={pageRef}>
        <section ref={medicationsRef}>
          <Header pageNumber={5} pageCount={12} />
          <div style={styles.twoNotEqualColumns}>
            <div style={{ fontSize: '14px' }}>
              <div style={styles.oneColumn}>Surgical History and Physical/Medication Reconciliation</div>
              <div style={styles.oneColumnLeftAlign}>
                <MedicalPassportField label="PreOp Diagnosis" name="page5.preOpDiag" fontSize={12} />
              </div>
              <div style={{ ...styles.twoNotEqualColumns1, border: '1px solid black' }}>
                <div>Planned Procedure</div>
                <MedicalPassportTextArea name="procedure.name" fontSize={12} />
              </div>
              <div style={{ ...styles.sixEqualColumns, borderTop: '0', fontSize: '12px' }}>
                <MedicalPassportField
                  label="Age"
                  name="page5.age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  fontSize={12} />
                <MedicalPassportField
                  label="Sex"
                  name="page5.sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toHighlightsString}
                  fontSize={11} />
                <MedicalPassportField
                  label="Ht"
                  name="page5.Ht"
                  path={['demographicInformation', 'bmi']}
                  reducer={toHeight}
                  fontSize={11} />
                <MedicalPassportField
                  label="Wt"
                  name="page5.Wt"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeightLbs}
                  fontSize={10} />
                <MedicalPassportField
                  label="KG"
                  name="page5.KG"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeight}
                  fontSize={11} />
                <MedicalPassportField
                  label="BMI"
                  name="page5.BMI"
                  path={['demographicInformation', 'bmi']}
                  reducer={toBMI}
                  fontSize={11} />
              </div>
            </div>
            <div>
              <div style={styles.oneColumnNoLeftYesBottom}>Polaris Surgery Center</div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>
                <MedicalPassportField label="Patient Name" name="procedure.patient.name" fontSize={12} />
              </div>
              <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>
                <div style={styles.twoNotEqualColumnsNoBorder}>
                  <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} fontSize={12} />
                  <MedicalPassportField label="Physician" name="procedure.physician.name" fontSize={11} />
                </div>
              </div>
              <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black', fontSize: '12px' }}>
                <div style={styles.twoNotEqualColumnsNoBorder}>
                  <MedicalPassportField label="DOS" name="procedure.serviceTime" fontSize={12} />
                  <MedicalPassportField label="Account #" name="page5.AccountNum" fontSize={12} />
                </div>
              </div>
            </div>
          </div>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '29% 42% 29%',
            borderBottom: 0, fontSize: '12px'
          }}>
            <div style={{ marginRight: '3px' }}>
              <MedicalPassportTextArea
                label="Previous Surgical History"
                name="anesthesia1"
                path={['priorSurgeries']}
                reducer={toHighlightsString}
                fontSize={10.5} />
            </div>
            <div style={{ marginRight: '3px' }}>
              <MedicalPassportTextArea
                label={'Current Medications'}
                name="medications1"
                path={['medications']}
                reducer={toHighlightsString}
                fontSize={10.5} />
            </div>
            <div style={{ fontSize: '10.5px' }}>
              <div style={{ fontSize: '12px', margin: '2px 0 5px' }}>Allergies/Sensitivities</div>
              <MedicationTextArea
                name="allergies1"
                value={combineArrays(allergyName, reaction)}
              />
            </div>
          </div>
        </section>
        <div style={{ ...styles.twoEqualColumns3, fontSize: '14px', pageBreakBefore: 'always' }}>
          <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
            Family History/SurgicalProblems
            <div>
              <MedicalPassportField label="PONV" name="page5.PONV" fontSize={12} />
              <div>
                <div style={{ fontSize: '14px' }}>Family/Self problems with anesthesia:</div>
              </div>
              <MedicationTextArea name="page5.problemAnesthesia" value={anesthesiaProblem} style={{ fontSize: '12px' }} />
            </div>
          </div>
          <div style={{ borderBottom: '1px solid black' }}>
            <div>
              <MedicalPassportTextArea label="Social History - Alcohol" name="page5.alc" path={['social', 'drinking']} fontSize={12} />
              <MedicalPassportTextArea label="Social Drugs" name="page5.drugs" path={['social', 'drugs']} fontSize={12} />
              <MedicalPassportField label="Cigarettes" name="page5.cigs" value={isSmoker(smoker)} fontSize={12} />
            </div>
          </div>
        </div>

        <div style={styles.greyBackground}>Review of Systems</div>
        <section>
          <div
            style={{
              border: '1px solid black',
              lineHeight: '18px',
              borderLeft: 0,
              borderRight: 0,
            }}
          >
            <MedicalPassportRow3
              label="1. Airway"
              name="systems.airway"
              index={0}
              style={{ borderTop: 0 }}
              paths={airway}
              reducer={toHighlightsString2}
              fontSize={11}
            />
            <MedicalPassportRow3
              label="2. Pulmonary"
              name="systems.pulmonary"
              paths={pulmonary}
              index={1}
              reducer={toHighlightsString2}
              style={{ minHeight: '20px' }}
              fontSize={11} />
            <MedicalPassportRow3
              label="3. Cardiovascular"
              name="systems.cardiovascular"
              paths={cardiovascular}
              index={2}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="4. Gastrointestinal"
              name="systems.gastrointestinal"
              paths={gastrointestinal}
              index={3}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="5. Renal"
              name="systems.renal"
              paths={renal}
              index={4}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="6. Endocrine"
              name="systems.endocrine2"
              paths={endocrine}
              index={5}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="7. Liver"
              name="systems.liver"
              index={6}
              paths={liver}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="8. Neurological"
              name="systems.neurological"
              paths={neurological}
              index={7}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />

            <MedicalPassportRow3
              label="9. Musculo-skeletal"
              name="systems.musculoskeletal"
              paths={musculoskeletal}
              index={8}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="10. Hematological"
              name="systems.hematological"
              paths={hematological}
              index={9}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="11. Psychiatric"
              name="systems.psychiatric"
              paths={psychiatric}
              index={10}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="12. Other"
              name="systems.other"
              paths={other}
              index={11}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
            <MedicalPassportRow3
              label="13. OB/Gyn"
              name="systems.gyn"
              paths={ob}
              index={12}
              style={{ minHeight: '20px' }}
              reducer={toHighlightsString2}
              fontSize={11} />
          </div>
        </section>
        <Footer pageNumber={5} pageCount={12} />
      </div>
    </div>
  );
};

export default Page5;
